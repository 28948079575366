
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'Home',
  setup () {
    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
